import { useState } from "react";
import "./App.css";
import logo from "./logo.png";

function App() {
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");
  const [messageType, setMessageType] = useState(""); // 'success' or 'error'

  const handleEmailChange = (event) => {
    setEmail(event.target.value);
  };

  const handleSubmit = () => {
    const data = { email: email };
    console.log("submit data");
    console.log(process.env.REACT_APP_BACKEND_API_URI);
    fetch(process.env.REACT_APP_BACKEND_API_URI, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(data),
    })
      .then((response) => {
        if (response.ok) {
          setMessage("Success! You will hear from us shortly!");
          setMessageType("success");
        } else {
          setMessage("Error! Please check if your email address is correct!");
          setMessageType("error");
        }
        return response.json();
      })
      .then((responseData) => {
        // Handle the response data if needed
        console.log("Response:", responseData);
      })
      .catch((error) => {
        setMessage("A fairy sytem error occurred. We are very sorry!");
        setMessageType("error");
        console.error("Error:", error);
      });
  };

  return (
    <div className="app">
      <h1>Pacifier Fairy</h1>
      <h2>Coming up soon!</h2>
      <div className="notificationText">
        Get Notified When the Pacifier Fairy Magic Arrives!
      </div>
      <div className="fairyLogo">
        <img src={logo} alt="Logo" />
      </div>
      <div className="inputContainer">
        <div className={messageType === "success" ? "success" : "error"}>
          {message}
        </div>
        <input
          placeholder="Enter your email to be notified!"
          type="email"
          value={email}
          onChange={handleEmailChange}
        />
        <button onClick={handleSubmit}>Let me know!</button>
      </div>
    </div>
  );
}

export default App;
